import React from 'react'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import {PageHeaderWrap} from '../pageheader/page-header.style';

const PageHeaderCustomBg = ({pageContext, location, title, fluidImageData, textColor='#f6f2ed'}) => {
    const { breadcrumb: { crumbs } } = pageContext;
    const disableLinks = [
        "/elements", 
        "/category", 
        "/profile", 
        "/date", 
        "/tag", 
        "/page", 
        "/blog", 
        "/blog/page", 
        "/blogs"
    ]
    const customCrumbLabel = location.pathname.toLowerCase();
    const crumbLabelArr = customCrumbLabel.split('/');
    const label = crumbLabelArr[crumbLabelArr.length - 1]
    const labelArr = label.split('-');

    const restProps = {
        textColor
    }

    return (
        <PageHeaderWrap fluid={fluidImageData} textColor={textColor}>
            <Breadcrumb
                title={title}
                crumbs={crumbs}
                crumbLabel={labelArr.join(' ')}            
                disableLinks={disableLinks}
            />
        </PageHeaderWrap>
    )
}

export default PageHeaderCustomBg

