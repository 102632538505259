import React from 'react';
import PropTypes from 'prop-types'
import {BoxIconWrap, BoxIconInner, BoxIconTop, Icon, Heading, BoxIconBottom, Text, ListBoxIconWrap, ListBoxTitle} from './box-icon.style'
import List, {ListItem} from '../../ui/list';

const BoxIcon = ({iconName, title, desc}) => {
    return(
        <BoxIconWrap>
            <BoxIconInner>
                <BoxIconTop>
                    {iconName && (
                        <Icon>
                            <i className={iconName}></i>
                        </Icon>
                    )}
                    {title && <Heading>{title}</Heading>}
                </BoxIconTop>
                {desc && (
                    <BoxIconBottom>
                        <Text>{desc}</Text>
                    </BoxIconBottom>
                )}
            </BoxIconInner>
        </BoxIconWrap>
    )
}

const ListBoxIcon = ({iconName, title, desc, list}) => {
    return(
        <BoxIconWrap>
            <BoxIconInner>
                <BoxIconTop>
                    {iconName && (
                        <Icon>
                            <i className={iconName}></i>
                        </Icon>
                    )}
                    {title && <Heading>{title}</Heading>}
                </BoxIconTop>
                {desc && (
                    <BoxIconBottom>
                        <Text>{desc}</Text>
                    </BoxIconBottom>
                )}
                { list && list.items && ( 
                    <ListBoxIconWrap>
                        <ListBoxTitle>
                            <Text>{list.title}</Text>
                        </ListBoxTitle>
                        <List layout={list.layout}>
                        {list.items.map(item => (
                            <ListItem>{item.text}</ListItem>
                        ))}
                        </List>
                    </ListBoxIconWrap>
                )}
            </BoxIconInner>
        </BoxIconWrap>
    )
}


BoxIcon.propTypes = {
    iconName: PropTypes.string,
    title: PropTypes.string,
    desc: PropTypes.string
}

BoxIcon.defaultProps = {
    iconName: "fal fa-life-ring",
    title: "Quality Assurance System",
    desc: "Our service offerings enhance customer experience throughout secure & highly functional end-to-end warranty management."
}

export {ListBoxIcon}

export default BoxIcon;