import React, {useState} from 'react';
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import parse from 'html-react-parser'
import SEO from "../../components/seo"
import Layout from '../../containers/layout/layout'
import Header from '../../containers/layout/header/header-one'
import Footer from '../../containers/layout/footer/footer-one'
import {Container, Box, Row, Col} from '../../components/ui/wrapper'
import Heading from '../../components/ui/heading'
import Text from '../../components/ui/text'
import Image from '../../components/image'
import ModalVideo from '../../components/ui/modal-video'
import BoxIcon, {ListBoxIcon} from '../../components/box-icon/layout-three'
import CTA from '../../containers/global/cta-area/section-one'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import AccordionWrap from '../../components/ui/accordion'
import {IntroArea, FaqArea, VideoBoxWrap, FeatureArea} from './service-template.style'
import PageHeaderCustomBg from '../../components/pageheadercustombg';
 
const ServiceTemplate = ({data, location, pageContext, ...restProps}) => {
    const pageData = data.itServicesJson;
    const seo = data.itServicesJson.seo;
    let bannerImg;
    if(pageData.banner_image){
        bannerImg = pageData.banner_image.childImageSharp.fluid;
    }
    const {
        introTextStyles: {leftHeading, leftText, rightHeading, rightText},
        featureStyels: {featureTitle}} = restProps;

    const [videoOpen, setVideoOpen] = useState(false);
    
    if(pageData.video){
        var {video_link} = pageData.video;
        var video_arr = video_link.split('=', -1);
        var video_id = video_arr[1];
    }

    const modalVideoClose = () => {
        setVideoOpen(false)
    }
    return (
        <Layout location={location}>
        <SEO
        title={seo.title}
        description={seo.description}
        image={`${seo.image.childImageSharp.gatsbyImageData.src}`}
        pathname={`/services/${pageContext.slug}`}
        />
            <Header/>
            <main className="site-wrapper-reveal">
                {(pageData.title || pageData.tagline) && (
                    <PageHeaderCustomBg
                        pageContext={pageContext}
                        location={location}
                        title={pageData.title}
                        fluidImageData={bannerImg}
                        textColor="#4b1e79"
                    />
                )}
                {pageData.introText && (
                    <IntroArea>
                        <Container>
                        {pageData.introText && (
                            <Row>
                                <Col lg={4}>
                                    <Heading {...leftHeading}>{parse(pageData.introText.sideHeading)}</Heading>
                                    {pageData.introText.sideText && <Text {...leftText}>{parse(pageData.introText.sideText)}</Text>}
                                </Col>

                                    <Col lg={{span: 7, offset: 1}}>
                                        <Box>
                                            {pageData.introText.heading && <Heading {...rightHeading}>{parse(pageData.introText.heading)}</Heading>}
                                            {pageData.introText.text && <Text {...rightText}>{parse(pageData.introText.text)}</Text>}
                                        </Box>
                                    </Col>
                            </Row>
                        )}                            
                        </Container>
                    </IntroArea>
                )}
                {(pageData.faq || pageData.video) && (
                    <FaqArea>
                        <Container>
                            <Row>
                                {pageData.faq && (
                                    <Col lg={6} pr="3rem">
                                        <AccordionWrap layout="two">
                                            <Accordion allowZeroExpanded preExpanded={[pageData.faq[0].id]}>
                                                {
                                                    pageData.faq.map((el, index) => {
                                                        return (
                                                            <AccordionItem id={el.id} key={el.id}>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton>
                                                                        {el.title}
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                    <p dangerouslySetInnerHTML={{ __html: el.desc }}/>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        )
                                                    })
                                                }
                                            </Accordion>     
                                        </AccordionWrap>
                                    </Col>
                                )}
                                {pageData.video && (
                                    <Col lg={6}>
                                        <VideoBoxWrap>
                                            {pageData.video.preview_image && <Image fluid image={pageData.video.preview_image.childImageSharp.gatsbyImageData} alt="Video preview" align="left"/>}
                                            {/* <VideoBtnWrap>
                                                <VideoButton skin="primary" onClick={modalVideoOpen}/>
                                            </VideoBtnWrap> */}
                                        </VideoBoxWrap>
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    </FaqArea>
                )}
                {pageData.features && (
                    <FeatureArea>
                        <Container>
                            {pageData.features.title && (
                                <Row>
                                    <Col col="12">
                                        <Heading {...featureTitle}>{pageData.features.title}</Heading>
                                    </Col>
                                </Row>
                            )}
                            {pageData.features.items && (
                                <Row>
                                    {pageData.features.items.map(feature => (
                                        <Col lg={6} key={feature.id}>
                                            {
                                                feature.list && (
                                                    <ListBoxIcon
                                                        title={feature.title}
                                                        iconName={feature.icon_name}
                                                        desc={feature.desc}
                                                        list={feature.list}
                                                    />
                                                )
                                            }
                                            {
                                                !feature.list && (
                                                    <BoxIcon
                                                        title={feature.title}
                                                        iconName={feature.icon_name}
                                                        desc={feature.desc}
                                                    />
                                            )}
                                        </Col>
                                    ))}
                                </Row>
                            )}
                        </Container>
                    </FeatureArea>
                )}
                {pageData.cta && (
                    <CTA 
                        headingText={pageData.cta.headingText}
                        letsTalkLink={pageData.cta.letsTalkLink}
                        infoLink={pageData.cta.infoLink}                        
                    />
                )}
            </main>
            <ModalVideo
                channel='youtube'
                videoId={video_id}
                isOpen={videoOpen}
                onClose={modalVideoClose}
            />
            <Footer/>
        </Layout>
    );
}
 
export const query = graphql `query ($slug: String!) {
  itServicesJson(fields: {slug: {eq: $slug}}) {
    title
    id
    banner_image {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 570, quality: 100){
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
            presentationWidth
            presentationHeight
        }
      }
    }
    introText {
      sideHeading
      sideText
      heading
      text
    }
    faq {
      id
      title
      desc
    }
    video {
      preview_image {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 570, height: 420, layout: CONSTRAINED)
        }
      }
      video_link
    }
    features {
      title
      items {
        id
        title
        icon_name
        desc
        list {
          title
          layout
          items {
            text
          }
        }
      }
    }
    cta {
      headingText
      letsTalkLink {
        path
        label
      }
      infoLink {
        path
        label
      }
    }
    seo {
      title
      description
      image {
        childImageSharp {
          gatsbyImageData(width: 480, height: 298, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  }
}
`;

ServiceTemplate.propTypes = {
    introTextStyles: PropTypes.object,
    titleStyle: PropTypes.object,
    taglineStyle: PropTypes.object,
    featureStyels: PropTypes.object
}

ServiceTemplate.defaultProps = {
    titleStyle: {
        as: 'h1',
        color: '#4b1e79',
        mb: '15px'
    },
    taglineStyle: {
        as: 'h5',
        fontweight: 400,
        color: '#fff'
    },
    introTextStyles: {
        leftHeading: {
            as: 'h3',
            fontSize: '38px',
            child: {
                color: 'primary'
            },
            responsive: {
                large: {
                    fontSize: '30px'
                },
                medium: {
                    mt: 0,
                    ml: '36px',
                    mb: '20px',
                    fontSize: '28px'
                },
                small: {
                    fontSize: '24px'
                }
            }
        },
        leftText: {
            mt: '15px',
            fontSize: '18px',
            color: '#696969',
            responsive: {
                medium: {
                    ml: '36px',
                    mb: '36px !important',
                }
            }
        },
        rightHeading: {
            as: 'h5',
            position: 'relative',
            pl: '34px',
            lineheight: 1.67,
            fontweight: 800,
            layout: 'quote',
            child: {
                color: 'primary'
            },
        },
        rightText: {
            mt: '15px',
            fontSize: '18px',
            ml: '34px',
            color: '#696969'
        }
    },
    featureStyels: {
        featureTitle: {
            as: 'h3',
            textalign: 'center',
            mb: '20px'
        }
    }
}

export default ServiceTemplate;